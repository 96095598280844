import * as React from 'react';
import {Alert, Button, ListGroupItem} from 'reactstrap';
import {PlaylistIndexManipulator} from "../playlists/PlaylistIndexManipulator";
import PlaylistData from "./data/PlaylistData";
import {RoutePaths} from "../RoutePaths";
import {PlaylistTexts} from "./PlaylistTexts";

interface Props {
    playlistData: PlaylistData
}

export function ImportedPlaylistInfo(props: Props) {

    function transformIntoOwnPrivatePlaylist() {
        const newIdentifier = PlaylistIndexManipulator.movePlaylistToCrew(props.playlistData, undefined)
        //don't use history.push because we want to reload the page
        window.location.href = RoutePaths.toPlaylist(newIdentifier);
    }

    return (
        <ListGroupItem key="qrCodeScannedPlaylistInfo">
            <Alert color="secondary">
                <p><b>{PlaylistTexts.ImportedPlaylistTitle}</b></p>
                <p>
                    Diese Playlist wurde durch das Scannen eines QR-Codes importiert. Deshalb kannst du sie nicht editieren und sie wird auch nicht mit deinen anderen Geräten
                    synchronisiert.<br/>
                    Du kannst diese Playlist aber jederzeit in eine eigene, private Playlist umwandeln.
                </p>
                <Button color="link" onClick={() => transformIntoOwnPrivatePlaylist()} className="p-0 m-0">Jetzt umwandeln</Button>


            </Alert>
        </ListGroupItem>
    );
}

