import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Button, Input, InputGroup} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useHotkeys} from "react-hotkeys-hook";
import {CyTitleEditable} from "./CyTitleEditable";
import {CyInputEditable} from "../common/CyInputEditable";

interface Props {
    content: string,
    updateContent: (content: string) => void;
    editAllowed: boolean
}

export function TitleEditable(props: Props) {

    const inputfield = useRef<HTMLInputElement>(null);

    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>("")

    useHotkeys('escape', () => cancelEditMode(), {enableOnFormTags: ['input']})
    useHotkeys('enter', () => saveAndCloseEditMode(), {enableOnFormTags: ['input']})

    function enterEditMode() {
        setIsEditing(true)
        setInputValue(props.content)
    }

    useEffect(() => {
        const element = inputfield.current
        if (element) {
            element.focus();
            element.select();
        }
    }, [isEditing]);

    function cancelEditMode() {
        setIsEditing(false)
    }

    function saveAndCloseEditMode() {
        props.updateContent(inputValue);
        setIsEditing(false)
    }

    function updateInputValue(evt: React.FormEvent<HTMLInputElement>) {
        setInputValue(evt.currentTarget.value)
    }

    return (
        <>
            {isEditing ? (
                <InputGroup>
                    <Input type="text" name="playlistName"
                           placeholder="Titel der Playlist eingeben..."
                           value={inputValue}
                           onChange={evt => updateInputValue(evt)}
                           innerRef={inputfield}
                           data-cy={CyInputEditable.InputField}
                    />
                    <Button color="primary" onClick={() => saveAndCloseEditMode()} data-cy={CyInputEditable.SaveButton}><FontAwesomeIcon icon={faCheck}/></Button>
                    <Button color="primary" onClick={() => cancelEditMode()}><FontAwesomeIcon icon={faTimes}/></Button>
                </InputGroup>
            ) : (<>
                <span data-cy={CyTitleEditable.SpanPlaylistTitle}>{props.content}</span>
                {props.editAllowed && <Button color="link" onClick={() => enterEditMode()} data-cy={CyInputEditable.EditButton}><FontAwesomeIcon icon={faPencilAlt}/></Button>}
            </>)}
        </>
    );
}