import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import PlaylistData from "../playlist/data/PlaylistData";
import './SongsheetCarousel.css';
import PlaylistItemData from "../playlist/data/PlaylistItemData";
import {useHistory} from "react-router-dom";
import PlaylistBanner from "./PlaylistBanner";
import useToggleValueWithLocalStoragePersistence from "../common/hooks/useToggleValueWithLocalStoragePersistence";
import Auth from "../auth/Auth";
import {Permission} from "../auth/Permission";
import CarouselSettingsSidebar from "./CarouselSettingsSidebar";
import CarouselPlaylistSidebar from "./CarouselPlaylistSidebar";
import {CarouselToolbar} from "./CarouselToolbar";
import {BeamerBroadcastChannelSender} from "../beamer/BeamerBroadcastChannelSender";
import {PlaylistRoutePaths} from "../playlist/PlaylistRoutePaths";
import {useMediaQuery} from "../common/hooks/useMediaQuery";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import {Swiper as SwiperClass} from "swiper/types";
import {Zoom} from "swiper";
import {SongshipSwiperSlide} from "./SongshipSwiperSlide";
import {SongsheetBeamerControl} from "../beamer/SongsheetBeamerControl";
import {
    useStateWithLocalStoragePersistenceAndDefault
} from "../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {VirtualSlide} from "./slide/VirtualSlide";
import {VirtualSlides} from "./slide/VirtualSlides";
import _ from "lodash";
import {VirtualInvalidSlide} from "./slide/VirtualInvalidSlide";
import {VirtualTextOnlySlide} from "./slide/VirtualTextOnlySlide";
import {VirtualSongsheetSlide} from "./slide/VirtualSongsheetSlide";
import {InvalidSlide} from "./slide/InvalidSlide";
import {TextOnlySongSlide} from "./slide/TextOnlySongSlide";
import {SongsheetSlide} from "./slide/SongsheetSlide";
import SwitchUtils from "../common/SwitchUtils";
import {useHotkeys} from "react-hotkeys-hook";
import {bigScreenThreshold} from "../App";
import {LocalStorageKey} from "../localStoragePersistence/common/LocalStorageKey";
import {RoutePaths} from "../RoutePaths";
import {Client} from "urql";
import {useCrewLiveContext} from "../live/CrewLiveContext";
import {DeviceInfo, useDeviceContext} from "../device/DeviceInfoContext";
import {PlaylistLivePosition} from "../live/playlist/PlaylistLivePosition";
import {pushPlaylistLivePosition} from "../live/playlist/PlaylistLivePositionPusher";
import Sidebar from "./Sidebar";
import {PlaylistLivePositionSidebarBody} from "./sidebar/liveposition/PlaylistLivePositionSidebarBody";

interface Props {
    playlistIdentifier: string
    playlistData: PlaylistData
    selectedItem: PlaylistItemData | undefined
    setSelectedItem: (item: PlaylistItemData | undefined) => void
    auth: Auth,
    graphQlClient: Client
}

const channel = new BeamerBroadcastChannelSender();

export function SongsheetCarousel(props: Props) {

    const {playlistData, selectedItem, setSelectedItem, graphQlClient, playlistIdentifier, auth} = props;

    const isBigScreen = useMediaQuery(`(min-width: ${bigScreenThreshold})`);

    const {
        deviceIdentifier,
        deviceReadableName,
        deviceColor,
        setDeviceReadableName,
        setDeviceColor
    } = useDeviceContext();

    const {
        currentCrewIdentifier,
        playlistLivePosition,
        playlistLivePositionEventSourceHandlerIsReady,
    } = useCrewLiveContext();

    const [showToolbar, setShowToolbar] = useState(true);
    const [showSettingsSidebar, setShowSettingsSidebar] = useState(false);
    const [showPlaylistSidebar, setShowPlaylistSidebar] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("SongsheetCarousel", "showPlaylistSidebar"), false);
    const [showPlaylistLivePositionSidebar, setShowPlaylistLivePositionSidebar] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("SongsheetCarousel", "showPlaylistLivePositionSidebar"), false);
    const [showMainContent, setShowMainContent] = useState(true);
    const [showBanner, setShowBanner] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("SongsheetCarousel", "showBanner"), true);
    const [isBeamerModeActiveSetting, setIsBeamerModeActiveSetting] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("SongsheetCarousel", "isBeamerModeActive"), false);
    const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
    const [activeLyricsPart, setActiveLyricsPart] = useState<number | undefined>(undefined);
    const [amountOfPagesInSongsheetCarousel, setAmountOfPagesInSongsheetCarousel] = useStateWithLocalStoragePersistenceAndDefault<number>(new LocalStorageKey("SongsheetCarousel", "amountOfPagesInSongsheetCarousel"), 1);
    const [listOfFollowedDevices, setListOfFollowedDevices] = useState<string[]>([]);

    const isBeamerModeActive = useCallback(() => {
        if (auth.hasPermissions([Permission.songsheetsRead])) {
            return isBeamerModeActiveSetting;
        } else {
            return true
        }
    }, [auth, isBeamerModeActiveSetting]);

    const [virtualSlides, setVirtualSlides] = useState<VirtualSlide[]>(VirtualSlides.buildVirtualSlides(playlistData.getItems(), isBeamerModeActive()));
    const [activePageInCarousel, setActivePageInCarouselInternal] = useState<number>(VirtualSlides.getSlideIndexOfItemId(virtualSlides, selectedItem?.itemInMemoryId) || 0);

    //Inside a callback, because we need this function in multiple effects
    //https://react.dev/reference/react/useCallback#preventing-an-effect-from-firing-too-often
    const setActivePageInCarousel = useCallback((newIndex: number) => {
        if (newIndex >= 0 && newIndex < virtualSlides.length) {
            setActiveLyricsPart(undefined);
            setActivePageInCarouselInternal(newIndex);
        }
    }, [virtualSlides.length]);

    // we do changes to the selectedItem via changing the active page to not end in conflicts
    const setSelectedItemViaActivePageInCarousel = useCallback((item: PlaylistItemData | undefined) => {
        setActivePageInCarousel(VirtualSlides.getSlideIndexOfItemId(virtualSlides, item?.itemInMemoryId) || 0);
    }, [setActivePageInCarousel, virtualSlides]);

    useHotkeys('escape', () => {
        const url = RoutePaths.toPlaylist(props.playlistIdentifier);
        history.push(url);
    })
    useHotkeys('left', () => setActivePageInCarousel(activePageInCarousel - 1), [setActivePageInCarousel, activePageInCarousel])
    useHotkeys('right', () => setActivePageInCarousel(activePageInCarousel + 1), [setActivePageInCarousel, activePageInCarousel])

    useEffect(() => {
        setVirtualSlides(VirtualSlides.buildVirtualSlides(playlistData.getItems(), isBeamerModeActive()))
    }, [playlistData, isBeamerModeActive]);

    //used to be able to switch between lyrics and songsheet mode
    //selectedItem is by purpose not part of the dependencies as this would update the activePageInCarousel again
    useEffect(() => {
        setSelectedItemViaActivePageInCarousel(selectedItem)
    }, [virtualSlides, setSelectedItemViaActivePageInCarousel]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //we define de speed explicitly because the default value sometimes is undefined while loading
        swiper && swiper.slideTo(activePageInCarousel, getSpeed());
    }, [activePageInCarousel, swiper]);

    const history = useHistory();

    useEffect(() => {
        const isAnySidebarVisible = showPlaylistSidebar || showSettingsSidebar || showPlaylistLivePositionSidebar;
        setShowMainContent(isBigScreen || !isAnySidebarVisible);
        setShowToolbar(isBigScreen || !isAnySidebarVisible);
    }, [isBigScreen, showPlaylistLivePositionSidebar, showPlaylistSidebar, showSettingsSidebar]);

    function setIsBeamerModeActive(active: boolean) {
        setIsBeamerModeActiveSetting(active);
        //because the texts are scrollable, we need to disable the swipe gesture in beamer mode (swiper cannot handle this)
        if (swiper) swiper.allowTouchMove = !active;
    }

    function previousPageInCarousel() {
        setActivePageInCarousel(activePageInCarousel - 1);
    }

    function nextPageInCarousel() {
        setActivePageInCarousel(activePageInCarousel + 1);
    }

    //Emptying the screen shall be sent to the beamer window
    useEffect(() => {
        if(activeLyricsPart === undefined){
            channel.emptyScreen();
        }else{
            //TODO: Sending everything else also via this effect
        }
    }, [activeLyricsPart]);

    useEffect(() => {
        setSelectedItem(VirtualSlides.getPlaylistEntryBySlideIndex(virtualSlides, activePageInCarousel));
    }, [activePageInCarousel, setSelectedItem, virtualSlides]);

    useEffect(() => {
        if (currentCrewIdentifier === undefined || !playlistLivePositionEventSourceHandlerIsReady) return;

        pushPlaylistLivePosition(
            graphQlClient,
            currentCrewIdentifier,
            {
                deviceIdentifier,
                deviceReadableName,
                deviceColor,
                playlistIdentifier: playlistIdentifier,
                timestamp: Date.now(),
                selectedPlaylistItemInMemoryId: VirtualSlides.getPlaylistEntryBySlideIndex(virtualSlides, activePageInCarousel)?.itemInMemoryId,
                viewMode: isBeamerModeActive() ? "lyrics" : "songsheet",
                currentLyricsPosition: activeLyricsPart,
                activePageInCarousel: activePageInCarousel,
            }
        )
    }, [activePageInCarousel, activeLyricsPart, currentCrewIdentifier, playlistLivePositionEventSourceHandlerIsReady, isBeamerModeActive, graphQlClient, playlistIdentifier, deviceIdentifier, deviceReadableName, deviceColor, virtualSlides]);

    useEffect(() => {
        const optHead = Array.from(playlistLivePosition.values())
            .filter(p => listOfFollowedDevices.includes(p.deviceIdentifier))
            .filter(p => p.playlistIdentifier === playlistIdentifier)
            .sort((a, b) => a.timestamp - b.timestamp)
            .pop();

        if (optHead === undefined) return;

        console.log("playlistLivePosition feature sets", optHead)
        if (optHead.viewMode === "lyrics" && isBeamerModeActive()) {
            optHead.activePageInCarousel !== undefined && setActivePageInCarousel(optHead.activePageInCarousel)
            setActiveLyricsPart(optHead.currentLyricsPosition)
        } else if (optHead.viewMode === "songsheet" && !isBeamerModeActive()) {
            optHead.activePageInCarousel && setActivePageInCarousel(optHead.activePageInCarousel)
        } else {
            const slideIndex = optHead.selectedPlaylistItemInMemoryId && VirtualSlides.getSlideIndexOfItemId(virtualSlides, optHead.selectedPlaylistItemInMemoryId)
            slideIndex !== undefined && (setActivePageInCarousel(slideIndex))
        }


    }, [playlistLivePosition, listOfFollowedDevices, playlistIdentifier, isBeamerModeActive, setActivePageInCarousel, virtualSlides]);

    function generateSwiper() {
        return <Swiper
            style={{flex: "1"}}
            speed={getSpeed()}
            modules={[Zoom]}
            slidesPerView={isBeamerModeActive() ? 1 : amountOfPagesInSongsheetCarousel}
            zoom={true}
            allowTouchMove={!isBeamerModeActive()} //initially, but also need to set on state const "swiper" because it is not updated by react
            onSlideChange={(swiper) => swiper.activeIndex !== activePageInCarousel && setActivePageInCarousel(swiper.activeIndex)}
            onSwiper={(s: SwiperClass) => setSwiper(s)}
        >
            {virtualSlides.map((v, i) => {
                    switch (v.tag) {
                        case "VirtualInvalidSlide" :
                            return <SwiperSlide key={v.buildKey()}>
                                <InvalidSlide/>
                            </SwiperSlide>
                        case "VirtualTextOnlySlide" :
                            return <SwiperSlide key={v.buildKey()}>
                                <TextOnlySongSlide/>
                            </SwiperSlide>
                        case "VirtualSongsheetSlide" :
                            return <SongshipSwiperSlide key={v.buildKey()}>
                                <SongsheetSlide pictureReference={v.pictureReference}/>
                            </SongshipSwiperSlide>
                        case "VirtualLyricsSlide" :
                            return <SwiperSlide key={v.buildKey()}>
                                <SongsheetBeamerControl
                                    tocEntry={v.toCEntry}
                                    channel={channel}
                                    activeLyricsPart={activePageInCarousel === i ? activeLyricsPart : undefined}
                                    setActiveLyricsPart={setActiveLyricsPart}
                                    key={v.buildKey()}
                                    playlistLivePositionArray={playlistLivePositionFiltered(playlistLivePosition)}
                                />
                            </SwiperSlide>
                        default:
                            return SwitchUtils.throwUnsupportedValue(v);
                    }
                }
            )}
        </Swiper>
    }

    function playlistLivePositionFiltered(playlistLivePosition: ReadonlyMap<string, PlaylistLivePosition>): readonly PlaylistLivePosition[] {
        console.log("playlistLivePosition", playlistLivePosition)
        return Array.from(playlistLivePosition.values())
            .filter(p => {
                return (
                    p.playlistIdentifier === props.playlistIdentifier
                    && p.activePageInCarousel === activePageInCarousel
                )
            })
    }

    function devicesInPlaylistFiltered(playlistLivePosition: ReadonlyMap<string, PlaylistLivePosition>): readonly DeviceInfo[] {
        return Array.from(playlistLivePosition.values())
            .filter(p => {
                return (
                    p.playlistIdentifier === props.playlistIdentifier
                )
            })
    }

    function openBeamerWindow() {
        window.open(PlaylistRoutePaths.toBeamerView(props.playlistIdentifier), "beamer", "fullscreen=yes");
    }

    return (
        /**
         * idea of the wrapper (see layout.png): http://jsfiddle.net/ch7n6/867/
         */
        <div className="wrapper">
            {showBanner && <PlaylistBanner {...props} />}
            <div style={{flex: "1 1 auto", display: "flex", overflowY: "auto"}}>
                {showMainContent && generateSwiper()}
                {showSettingsSidebar &&
                    <Sidebar
                        closeSidebar={() => setShowSettingsSidebar(false)}
                        title="Einstellungen"
                        showCloseButton={!isBigScreen}
                    >
                        <CarouselSettingsSidebar
                            setShowBanner={setShowBanner}
                            showBanner={showBanner}
                            beamerBroadcastChannelSender={channel} auth={props.auth}
                            isBeamerModeActive={isBeamerModeActive()}
                            setIsBeamerModeActive={setIsBeamerModeActive}
                            openBeamerWindow={openBeamerWindow}
                            amountOfPagesInSongsheetCarousel={amountOfPagesInSongsheetCarousel}
                            setAmountOfPagesInSongsheetCarousel={setAmountOfPagesInSongsheetCarousel}
                        />
                    </Sidebar>}
                {showPlaylistSidebar &&
                    <Sidebar
                        closeSidebar={() => setShowPlaylistSidebar(false)}
                        title="Playlist"
                        showCloseButton={!isBigScreen}
                    >
                        <CarouselPlaylistSidebar
                            playlistData={props.playlistData}
                            selectedItem={props.selectedItem}
                            setSelectedItem={setSelectedItemViaActivePageInCarousel}
                            closeSidebar={() => setShowPlaylistSidebar(false)}
                            isBigScreen={isBigScreen}/>
                    </Sidebar>}
                {showPlaylistLivePositionSidebar &&
                    <Sidebar
                        closeSidebar={() => setShowPlaylistLivePositionSidebar(false)}
                        title="Gemeinsamer Modus"
                        showCloseButton={!isBigScreen}>
                        <PlaylistLivePositionSidebarBody
                            deviceInfo={{deviceIdentifier, deviceReadableName, deviceColor}}
                            setDeviceReadableName={setDeviceReadableName}
                            setDeviceColor={setDeviceColor}
                            devicesInPlaylist={devicesInPlaylistFiltered(playlistLivePosition)}
                            listOfFollowedDevices={listOfFollowedDevices}
                            setListOfFollowedDevices={setListOfFollowedDevices}
                            currentCrewIdentifier={currentCrewIdentifier}
                        />
                    </Sidebar>}
                {showToolbar && <div className="toolbar">
                    <CarouselToolbar
                        playlistIdentifier={props.playlistIdentifier}
                        showPlaylistSidebar={showPlaylistSidebar}
                        showPlaylistLivePositionSidebar={showPlaylistLivePositionSidebar}
                        showSettingsSidebar={showSettingsSidebar}
                        setShowPlaylistSidebar={setShowPlaylistSidebar}
                        setShowPlaylistLivePositionSidebar={setShowPlaylistLivePositionSidebar}
                        setShowSettingsSidebar={setShowSettingsSidebar}
                        isBeamerModeActive={isBeamerModeActive()}
                        setIsBeamerModeActive={setIsBeamerModeActive}
                        previousPageInCarousel={previousPageInCarousel}
                        nextPageInCarousel={nextPageInCarousel}
                        emptyLyricsScreen={() => setActiveLyricsPart(undefined)}
                        openBeamerWindow={openBeamerWindow}/>
                </div>}
            </div>
        </div>
    );
}

function getSpeed() {
    return runningInCypress() ? 0 : 300;
}

function runningInCypress() {
    // @ts-ignore
    return window.Cypress;
}