import * as React from 'react';
import {CardBody, CardTitle, FormGroup, FormText} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {LivePositionMarker} from "../../../live/LivePositionMarker";
import {DeviceInfo} from "../../../device/DeviceInfoContext";
import {CyLivePosition} from "./CyLivePosition";
import {InputEditable} from "../../../common/InputEditable";

interface Props {
    deviceInfo: DeviceInfo
    setDeviceReadableName: (name: string) => void
    setDeviceColor: (color: string) => void
}

export function OwnDevice(props: Props) {

    return (
        <CardBody>
            <CardTitle><FontAwesomeIcon icon={faUser}/> Eigenes Gerät</CardTitle>
            <FormGroup>
                <div>
                    <InputEditable
                        cyData={CyLivePosition.ChangeDeviceName}
                        name={"deviceReadableName"}
                        content={props.deviceInfo.deviceReadableName}
                        updateContent={props.setDeviceReadableName}
                        editAllowed={true}
                    >
                        {props.deviceInfo.deviceReadableName}
                    </InputEditable>
                    <InputEditable
                        type="color"
                        cyData={CyLivePosition.ChangeDeviceColor}
                        name={"deviceColor"}
                        content={props.deviceInfo.deviceColor}
                        updateContent={props.setDeviceColor}
                        editAllowed={true}
                        style={{height: "38px"}}
                    >
                        <LivePositionMarker color={props.deviceInfo.deviceColor} deviceIdentifier={props.deviceInfo.deviceIdentifier}/>
                    </InputEditable>
                </div>
                <FormText>Der Name und die Farbe des Geräts, welcher den anderen Benutzern angezeigt werden soll (z.
                    B.
                    Dominiks Handy).</FormText>
            </FormGroup>
        </CardBody>
    );

}

