import {useEffect, useState} from "react";
import {BackendConfig} from "../Config";
import {buildLiveUpdateEventSourceUrl} from "./LiveUpdateUrlBuilder";

// crewId can be undefined for the private queue
export function useCrewLiveEventSource(crewId: string | undefined): { eventSource: EventSource | undefined } {
    const [eventSource, setEventSource] = useState<EventSource | undefined>(undefined);

    useEffect(() => {
        if (!crewId) {
            console.debug("No crewId, not opening event source.")
            return
        }

        // opening a connection to the server to begin receiving events from it
        const source = new EventSource(`${BackendConfig.baseUrl}${buildLiveUpdateEventSourceUrl(crewId)}`);

        //we don't attach the onmessage event handler, this can be done by the concrete listeners, that use this stream

        source.onerror = (event: Event): any => {
            console.error("EventSource failed:", event);
        }

        window.addEventListener('beforeunload', () => {
            console.debug("Closing event source")
            source.close();
        });

        setEventSource(source);

        // terminating the connection on component unmount
        return () => source.close();

    }, [crewId]);

    return {eventSource};
}