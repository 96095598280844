import * as React from 'react';
import {Alert, CardBody, CardTitle} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPeopleGroup, faWarning} from "@fortawesome/free-solid-svg-icons";
import {LivePositionTexts} from "./LivePositionTexts";

export function NoCrewInfo() {
    return (
        <CardBody>
            <CardTitle>
                <FontAwesomeIcon icon={faPeopleGroup}/> Andere aktive Geräte
            </CardTitle>
            <Alert color="warning">
                <FontAwesomeIcon icon={faWarning} color="warning"/> {LivePositionTexts.CrewNeededInfo}
            </Alert>
        </CardBody>
    );

}

