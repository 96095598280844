import * as React from 'react';
import {CardBody, CardTitle, FormGroup, FormText, Input, Label} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
import {LivePositionMarker} from "../../../live/LivePositionMarker";
import {DeviceInfo} from "../../../device/DeviceInfoContext";
import OwnNavigationDisabledWarning from "./../../OwnNavigationDisabledWarning";
import {CrewInformationUb} from "../../../gql/graphql";
import {useCrewQuery} from "../../../crew/useCrewQuery";
import {CyLivePosition} from "./CyLivePosition";

interface Props {
    deviceInfo: DeviceInfo
    devicesInPlaylist: readonly DeviceInfo[]
    listOfFollowedDevices: string[]
    setListOfFollowedDevices: (value: (prevState: string[]) => string[]) => void
    currentCrewIdentifier: string
}

export function OtherActiveDevices(props: Props) {

    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    const currentCrew = crewInformationMaybe?.find(i => i.identifier === props.currentCrewIdentifier)

    return (
        <CardBody data-cy={CyLivePosition.OtherDevices}>
            <CardTitle>
                <FontAwesomeIcon icon={faPeopleGroup}/> Aktive Geräte in {currentCrew && `'${currentCrew.title}'`}
            </CardTitle>
            <FormText>Wähle, welchen Geräten du folgen möchtest.</FormText>
            {props.devicesInPlaylist.map((info: DeviceInfo) => <div key={info.deviceIdentifier}>
                <FormGroup switch>
                    <Input
                        id={"switch-" + info.deviceIdentifier}
                        type="switch"
                        disabled={info.deviceIdentifier === props.deviceInfo.deviceIdentifier}
                        checked={props.listOfFollowedDevices.includes(info.deviceIdentifier)}
                        onChange={() => {
                            props.setListOfFollowedDevices(old => (old.includes(info.deviceIdentifier)) ? old.filter(i => i !== info.deviceIdentifier) : [...old, info.deviceIdentifier]);
                        }}
                    />

                    <Label for={"switch-" + info.deviceIdentifier}>{info.deviceReadableName}</Label>
                    <LivePositionMarker
                        color={info.deviceColor}
                        deviceIdentifier={info.deviceIdentifier}
                        className={"ms-2"}
                    />
                </FormGroup>
            </div>)}
            {props.listOfFollowedDevices.length > 0 && <OwnNavigationDisabledWarning/>}
        </CardBody>
    );

}

