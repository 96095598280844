import {BroadcastChannel} from "broadcast-channel";
import {BeamerBroadcastChannel} from "./BeamerBroadcastChannel";
import {useEffect, useState} from "react";


export function useBeamerBroadcastChannelReceiver(
    setContent: (content: string) => void,
    setFontSizeInViewHeight: (value: (((prevState: number) => number) | number)) => void,
) {
    const [channel] = useState(() => new BroadcastChannel(BeamerBroadcastChannel.ChannelName))

    useEffect(() => {

        const increaseFontSize = () => setFontSizeInViewHeight(prev => Math.min(16, prev + 1))
        const decreaseFontSize = () => setFontSizeInViewHeight(prev => Math.max(2, prev - 1))

        const handler = function (ev: string) {
            if (ev === BeamerBroadcastChannel.IncreaseFontSize) {
                increaseFontSize();
            } else if (ev === BeamerBroadcastChannel.DecreaseFontSize) {
                decreaseFontSize();
            } else {
                setContent(ev);
            }
        }
        channel.addEventListener('message', handler);
        return () => {
            channel.close();
            channel.removeEventListener('message', handler);
        }
    }, [channel, setContent, setFontSizeInViewHeight]);
}