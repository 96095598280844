import * as React from 'react';
import {Button, Card, CardBody, CardHeader, CloseButton, Col, Row} from 'reactstrap';
import {faEdit, faHandsHelping, faMoneyCheckAlt, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './beamerHelp.png'
import beamerHelp from './beamerHelp.png';
import notenView from './notenView.png';
import crewLiveMode from './crewLiveMode.png';
import 'react-medium-image-zoom/dist/styles.css'
import Zoom from 'react-medium-image-zoom'
import {ScottyLink} from "../helper/ScottyLink";
import {ContactFormLink} from "../helper/ContactFormLink";
import {PlaylistIndexTexts} from "../playlists/PlaylistIndexTexts";
import {CrewInformationUb} from "../gql/graphql";
import {useCrewQuery} from "../crew/useCrewQuery";

interface Props {
    closeDialog: () => void
}


export function ShortHelp(props: Props) {

    const crewInformationMaybe: ReadonlyArray<CrewInformationUb> | undefined = useCrewQuery()

    return (
        (crewInformationMaybe !== undefined && crewInformationMaybe.length > 0) ?
            <Card className="mt-4">
                <CardHeader style={{backgroundColor: "#f8f9fa"}}>
                    <Row>
                        <Col>
                            <h2 className={"light-header"}>
                                <FontAwesomeIcon icon={faHandsHelping}/> Was möchtest du tun?
                            </h2>
                        </Col>
                        <Col xs={"auto"}>
                            <CloseButton onClick={() => props.closeDialog()}/>
                        </Col>
                    </Row>


                </CardHeader>
                <CardBody>
                    <h5>Lieder bearbeiten (Texte & Noten)</h5>
                    <p>
                        Klicke auf <FontAwesomeIcon icon={faEdit}/> <i>{PlaylistIndexTexts.EditSongs}</i> oben im Menü.
                        Die Liederdatenbank öffnet sich in einem neuen
                        Fenster. Du wirst automatisch angemeldet.<br/>
                    </p>
                    <p>
                        <a href={ScottyLink()} target="_blank" rel="noreferrer"><Button color="primary"><FontAwesomeIcon
                            icon={faEdit}/> {PlaylistIndexTexts.EditSongs}</Button></a>
                    </p>
                    <h5>Playlist erstellen</h5>
                    <p>
                        Erstelle oder importiere eine Playlist. Anschliessend kannst du
                    </p>
                    <ul>
                        <li><a href="#beamLyrics">Texte beamen</a></li>
                        <li><a href="#showSongsheets">Noten anzeigen</a></li>
                    </ul>
                    <p>
                        Um eine Playlist zu erstellen, klicke auf <FontAwesomeIcon icon={faPlus}/>
                        <i>{PlaylistIndexTexts.AddPlaylist}</i> unten.
                    </p>

                    <h5 id="beamLyrics">Texte beamen</h5>
                    <p>
                        Sobald du eine Playlist hast, kannst du Texte mit einem Beamer projizieren.
                    </p>
                    <ol>
                        <li>Neues Fenster für den Beamer öffnen</li>
                        <li>Text anklicken, der gebeamet werden soll</li>
                        <li>Text löschen</li>
                        <li>Schriftgrösse ändern etc.</li>
                    </ol>
                    <div className="mb-4">
                        <Zoom> <img src={beamerHelp} className="img-thumbnail" width="300px"/></Zoom>
                    </div>

                    <h5>Gemeinsamer Modus</h5>
                    <p>
                        Du kannst anderen Geräten folgen, wenn sie mit dem Internet verbunden sind. So kannst du zum
                        Beispiel den Laptop, welcher die Liedtexte projiziert, mit deinem Handy fernsteuern.
                    </p>
                    <ol>
                        <li>Öffne den gemeinsamen Modus</li>
                        <li>Folge einem anderen Gerät (z. B. "Peters Handy")</li>
                        <li>Peter kann auf seinem Handy einen Text anwählen</li>
                        <li>Auf dem Mac Book von Karl und im Beamer-Fenster von Karls Mac Book wird dieser Text angezeigt.</li>
                    </ol>
                    <div className="mb-4">
                        <Zoom> <img src={crewLiveMode} className="img-thumbnail" width="300px"/></Zoom>
                    </div>

                    <h5 id="showSongsheets"><FontAwesomeIcon icon={faMoneyCheckAlt}/> Noten anzeigen</h5>
                    <p>
                        Mit der kostenpflichtigen Version von Songship, Songship Pro, kannst du die Noten direkt in der
                        Playlist anzeigen.<br/>
                        <a href={ContactFormLink} target="_blank" rel="noreferrer">Mit diesem Formular</a> kannst du
                        Songship Pro kaufen.
                    </p>
                    <div className="mb-2">
                        <Zoom> <img src={notenView} className="img-thumbnail" width="300px"/></Zoom>
                    </div>
                    <p>
                        <a href={ContactFormLink} target="_blank" rel="noreferrer"><Button
                            color="primary"><FontAwesomeIcon icon={faMoneyCheckAlt}/> Songship Pro
                            kaufen</Button></a>
                    </p>

                    <h5>Hilfe</h5>
                    <p>
                        Benötigst du weitere Hilfe? <a href={ContactFormLink} target="_blank" rel="noreferrer">Mit
                        diesem Formular</a> kannst du uns kontaktieren.
                    </p>

                    <h5>Alles klar!</h5>
                    <p>
                        Dann kannst du diesen Hilfedialog schliessen.
                    </p>
                    <Button color="primary" onClick={() => props.closeDialog()}><FontAwesomeIcon
                        icon={faTimes}/> Hilfe
                        schliessen</Button>

                </CardBody>
            </Card>
            : <></>);
}