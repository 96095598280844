import * as React from 'react';
import {useEffect, useState} from 'react';
import {PlaylistIndexManipulator} from "../../../playlists/PlaylistIndexManipulator";
import {TocEntryDTO} from "../../../dto/v1/toc/TocEntryDTO";
import {Alert} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faHome, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {PlaylistFromUrlImportParser} from "./PlaylistFromUrlImportParser";
import {TocIdDTO} from "../../../dto/v3/toc/TocIdDTO";
import Header from "../../../Header";
import {
    PlaylistLocalStoragePersistence
} from "../../../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {LoadingMessage} from "../../../LoadingMessage";
import {RoutePaths} from "../../../RoutePaths";
import {PlaylistImportTexts} from "./PlaylistImportTexts";

interface Props {
    importIdentifier: string
    lookupSong: (id: TocIdDTO) => TocEntryDTO | undefined
}

enum ImportState {
    Working = 0,
    ImportSuccessful,
    AlreadyExists,
    FailureImportError
}

const playlistLocalStoragePersistence = new PlaylistLocalStoragePersistence();

function PlaylistUrlImportDialog(props: Props) {
    const [importState, setImportState] = useState<ImportState>(ImportState.Working);

    useEffect(() => {
        if (playlistLocalStoragePersistence.doesDtoExist(props.importIdentifier)) {
            setImportState(ImportState.AlreadyExists);
            return;
        }

        const importedPlaylist = new PlaylistFromUrlImportParser().createPlaylistFromUrl(props.importIdentifier, window.location.href, props.lookupSong);
        if (importedPlaylist) {
            PlaylistIndexManipulator.importPlaylist(importedPlaylist);
            setImportState(ImportState.ImportSuccessful);
        } else {
            setImportState(ImportState.FailureImportError);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Header/>
            <br/>
            {renderState()}
        </>
    );

    function renderState() {
        switch (importState) {
            case ImportState.Working:
                return renderWorking();
            case ImportState.AlreadyExists:
                return renderAlreadyExists();
            case ImportState.ImportSuccessful:
                return renderImportSuccessful();
            case ImportState.FailureImportError:
                return renderFailureImportError();
        }
    }

    function renderWorking() {
        return (
            <LoadingMessage message={"Playlist wird importiert. Bitte warten..."}/>
        );
    }

    function renderImportSuccessful() {
        return (
            <Alert color="success">
                <h2 className={"light-header"}><FontAwesomeIcon
                    icon={faInfoCircle}/> {PlaylistImportTexts.ImportSuccessfulTitle}
                </h2>
                <span>
                    Die Playlist wurde erfolgreich importiert.
                </span>
                <br/><br/>
                <Link to={`/${RoutePaths.PLAYLIST}/${props.importIdentifier}`}>{PlaylistImportTexts.OpenPlaylistLink}</Link>
            </Alert>
        );
    }

    function renderAlreadyExists() {
        return (
            <Alert color="success">
                <h2 className={"light-header"}>
                    <FontAwesomeIcon icon={faInfoCircle}/> {PlaylistImportTexts.PlaylistAlreadyExistsTitle}
                </h2>
                <span>
                    Diese Playlist existiert auf diesem Gerät bereits.
                    Sie wird deshalb nicht erneut importiert, aber du kannst sie jetzt öffnen.
                </span>
                <br/><br/>
                <Link to={`/${RoutePaths.PLAYLIST}/${props.importIdentifier}`}>{PlaylistImportTexts.OpenPlaylistLink}</Link>
            </Alert>
        );
    }

    function renderFailureImportError() {
        return (
            <Alert color="danger">
                <h2 className={"light-header"}><FontAwesomeIcon icon={faExclamationTriangle}/> Fehler beim Import</h2>
                <span>
                    Die Playlist konnte nicht korrekt importiert werden. Melde dich bitte bei uns, um Unterstützung zu erhalten.
                </span>
                <br/><br/>
                <Link to="/"><FontAwesomeIcon icon={faHome}/> Übersicht</Link>
            </Alert>
        );
    }
}

export default PlaylistUrlImportDialog;
