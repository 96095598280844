import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Badge} from "reactstrap";
import PlaylistData from "../playlist/data/PlaylistData";
import PlaylistItemData from "../playlist/data/PlaylistItemData";
import ArrayUtils from "../common/ArrayUtils";
import {useCrewLiveContext} from "../live/CrewLiveContext";
import {PlaylistLivePosition} from "../live/playlist/PlaylistLivePosition";
import {LivePositionMarker} from "../live/LivePositionMarker";
import {useDeviceContext} from "../device/DeviceInfoContext";
import {CyPlaylistBanner} from "./CyPlaylistBanner";

interface Props {
    playlistData: PlaylistData;
    selectedItem: PlaylistItemData | undefined;
}

// When a new item is selected, we scroll to the item before and then to the one after.
// As long as the width of the display is big enough to show three entries, this ensures that we always will be able to see the entry before and after.
export default function PlaylistBanner(props: Props) {

    const {
        playlistLivePosition
    } = useCrewLiveContext();

    const {
        deviceIdentifier,
    } = useDeviceContext();

    const selectedItemBeforeBatchRef: React.MutableRefObject<HTMLElement | null> = useRef(null);
    const selectedItemAfterBatchRef: React.MutableRefObject<HTMLElement | null> = useRef(null);

    const [itemToScrollToAfterSelectedItem, setItemToScrollToAfterSelectedItem] = useState<PlaylistItemData | undefined>(undefined);
    const [itemToScrollToBeforeSelectedItem, setItemToScrollToBeforeSelectedItem] = useState<PlaylistItemData | undefined>(undefined);

    const {playlistData, selectedItem} = props;
    useEffect(() => {
        const previousItem = ArrayUtils.previousOf<PlaylistItemData>(playlistData.getItems(), i => i.itemInMemoryId === selectedItem?.itemInMemoryId)
        setItemToScrollToBeforeSelectedItem(previousItem ? previousItem : undefined);
        const followingItem = ArrayUtils.followingOf<PlaylistItemData>(playlistData.getItems(), i => i.itemInMemoryId === selectedItem?.itemInMemoryId)
        setItemToScrollToAfterSelectedItem(followingItem ? followingItem : undefined);
    }, [playlistData, selectedItem]);


    useEffect(() => {
        selectedItemBeforeBatchRef.current?.scrollIntoView(true);
        selectedItemAfterBatchRef.current?.scrollIntoView(true);
    }, [itemToScrollToAfterSelectedItem, itemToScrollToBeforeSelectedItem]);

    function generateDeviceInfo(itemInMemoryId: number): React.ReactNode {
        return Array.from(playlistLivePosition.values())
            .filter(e => {
                return e.playlistIdentifier === props.playlistData.getIdentifier()
                    && e.deviceIdentifier !== deviceIdentifier // exclude own device
                    && e.selectedPlaylistItemInMemoryId !== undefined
                    && e.selectedPlaylistItemInMemoryId === itemInMemoryId
            })
            .map((p: PlaylistLivePosition) => {
                return <LivePositionMarker color={p.deviceColor} key={p.deviceIdentifier}
                                           deviceIdentifier={p.deviceIdentifier} className="ms-1"/>
            })
    }

    return (
        <div
            data-cy={CyPlaylistBanner.PlaylistBanner}
            style={{
                width: "100%",
                overflowX: "hidden",
                whiteSpace: "nowrap",
                flexShrink: 0,
                backgroundColor: "rgb(248, 249, 250)"
            }}
            className="text-center"
        >
            {props.playlistData.getItems().map(playlistEntry => {
                const active = playlistEntry.itemInMemoryId === props.selectedItem?.itemInMemoryId;
                const isItemToScrollToBeforeSelectedItem = playlistEntry.itemInMemoryId === itemToScrollToBeforeSelectedItem?.itemInMemoryId;
                const isItemToScrollToAfterSelectedItem = playlistEntry.itemInMemoryId === itemToScrollToAfterSelectedItem?.itemInMemoryId;
                return <Badge
                    pill
                    className="me-1"
                    color={active ? "warning" : "secondary"}
                    innerRef={el => {
                        if (isItemToScrollToBeforeSelectedItem) selectedItemBeforeBatchRef.current = el;
                        if (isItemToScrollToAfterSelectedItem) selectedItemAfterBatchRef.current = el;
                    }}
                    key={playlistEntry.itemInMemoryId}
                >
                    {playlistEntry.toCEntry ? playlistEntry.toCEntry.title : "Ungültiger Eintrag"}
                    {generateDeviceInfo(playlistEntry.itemInMemoryId)}
                </Badge>

            })}
        </div>

    );

}