import PlaylistItemData from "../../playlist/data/PlaylistItemData";
import {VirtualSlide} from "./VirtualSlide";
import {VirtualInvalidSlide} from "./VirtualInvalidSlide";
import {VirtualTextOnlySlide} from "./VirtualTextOnlySlide";
import {VirtualSongsheetSlide} from "./VirtualSongsheetSlide";
import {VirtualLyricsSlide} from "./VirtualLyricsSlide";
import {ItemId} from "../../playlist/data/PlaylistTypes";


export const VirtualSlides = {
    buildVirtualSlides(items: readonly PlaylistItemData[], isBeamerModeActive: boolean): VirtualSlide[] {
        return isBeamerModeActive ? buildLyricsSlides(items) : buildSongsheetSlides(items)
    },

    getSlideIndexOfItemId(virtualSlides: readonly VirtualSlide[], itemId?: ItemId): number | undefined {
        const index = virtualSlides.findIndex((item) => item.playlistItemData.itemInMemoryId === itemId);
        return index === -1 ? undefined : index;
    },

    getPlaylistEntryBySlideIndex(virtualSlides: readonly VirtualSlide[], slideIndex: number): PlaylistItemData | undefined {
        return virtualSlides[slideIndex]?.playlistItemData
    }
}

function buildSongsheetSlides(items: ReadonlyArray<PlaylistItemData>): VirtualSlide[] {
    return items.flatMap(item => buildSongsheetSlidesForItem(item));
}

function buildSongsheetSlidesForItem(item: PlaylistItemData): VirtualSlide[] {
    if (item.toCEntry === undefined) {
        return [new VirtualInvalidSlide(item)]
    } else if (item.toCEntry.pictureReferences.length === 0) {
        return [new VirtualTextOnlySlide(item)]
    } else {
        return item.toCEntry.pictureReferences.map(r => new VirtualSongsheetSlide(item, r))
    }
}

function buildLyricsSlides(items: ReadonlyArray<PlaylistItemData>): VirtualSlide[] {
    return items.map(item => buildLyricsSlide(item))
}

function buildLyricsSlide(item: PlaylistItemData): VirtualSlide {
    if (item.toCEntry === undefined) {
        return new VirtualInvalidSlide(item)
    } else {
        return new VirtualLyricsSlide(item, item.toCEntry)
    }
}
