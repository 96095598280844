import {validate} from 'uuid';
import _ from "lodash";
import {JsonParsing, ParseError} from "../../dto/parsing/JsonParsing";
import {Result} from "neverthrow";
import {ACCESS_TOKEN_TO_USE_STORAGE_KEY} from "../../auth/stub/StubTokenDefinition";
import {LocalStorageKey} from "./LocalStorageKey";

export default class LocalStoragePersistence {
    private static readonly LOCAL_STORAGE_KEY_IDENTIFIER_SEPARATOR = "-";

    private static readonly ENTRIES_TO_NOT_BE_REMOVED_DURING_DELETION = [
        ACCESS_TOKEN_TO_USE_STORAGE_KEY
    ]

    public static extractIdentifierFromLocalStorageKey(entityName: string, localStorageKey: string): string | undefined {
        const extracted = localStorageKey.replace(entityName + this.LOCAL_STORAGE_KEY_IDENTIFIER_SEPARATOR, "");
        if (validate(extracted)) {
            return extracted;
        } else {
            console.warn("extractIdentifierFromLocalStorageKey tried to extract invalid uuid from localStorageKey=" + localStorageKey + " with entityName=" + entityName + " extracedResult=" + extracted);
            return undefined;
        }
    }

    private static isKeyValidEntityIdentifier(entityName: string, localStorageKey: string): boolean {
        const extracted = localStorageKey.replace(entityName + this.LOCAL_STORAGE_KEY_IDENTIFIER_SEPARATOR, "");
        return validate(extracted);
    }

    //TODO: Put errors in the response type
    public static read<T, D>(key: LocalStorageKey, fallback: D): T | D {
        const localStorageValue = localStorage.getItem(key.localStorageKey);
        if (
            localStorageValue
            && localStorageValue !== "undefined" //Because localStorage.setItem("aKey", undefined) will read as "undefined"
        ) {
            const parseResult: Result<T, ParseError> = new JsonParsing().parse(localStorageValue)
            return parseResult.match(parsedValue => parsedValue, e => {
                throw e;
            });
        } else {
            return fallback;
        }
    }

    public static persist<T>(key: LocalStorageKey, newValue: T) {
        console.debug("persisting state to local storage " + key.localStorageKey, newValue);
        localStorage.setItem(key.localStorageKey, JSON.stringify(newValue));
    }

    public static doesKeyExist(key: LocalStorageKey): boolean {
        const localStorageValue = localStorage.getItem(key.localStorageKey);
        return !!localStorageValue;
    }

    public static loadAllIdentifiersForEntity(entityName: string): ReadonlyArray<string> {
        const identifiers: (string | undefined)[] = Object.keys(localStorage)
            .filter(key => this.isKeyValidEntityIdentifier(entityName, key))
            .map(localStorageKey => LocalStoragePersistence.extractIdentifierFromLocalStorageKey(entityName, localStorageKey));

        return _.uniq(_.compact(identifiers));
    }

    public clearLocalStorageWithExceptions() {
        const backedUpEntries: Map<string, string | null> = new Map();
        LocalStoragePersistence.ENTRIES_TO_NOT_BE_REMOVED_DURING_DELETION.forEach(key => {
            backedUpEntries.set(key, localStorage.getItem(key));
        })

        localStorage.clear();

        backedUpEntries.forEach((value, key) => {
            if (value !== null) {
                localStorage.setItem(key, value);
            }
        })
    }
}