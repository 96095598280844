import * as React from 'react';
import './appinitandstate/TocAvailabilityNotice.css';
import {TocAvailability} from "./appinitandstate/TocAvailability";
import {BackendServerAvailability} from "./appinitandstate/BackendServerAvailability";
import Auth from "./auth/Auth";
import MainMenu from "./mainmenu/MainMenu";
import {OfflineUpdateSummary} from "./offline/OfflineUpdateSummary";
import PlaylistIndex from "./playlists/PlaylistIndex";
import {TocEntryDTO} from "./dto/v1/toc/TocEntryDTO";
import OfflineComponentsHolder from "./offline/OfflineComponentsHolder";
import {TocIdDTO} from "./dto/v3/toc/TocIdDTO";
import Header from "./Header";
import {NoCrewMembershipFoundAlert} from "./crew/NoCrewMembershipFoundAlert";
import {ShortHelp} from "./help/ShortHelp";
import {
    useStateWithLocalStoragePersistenceAndDefault
} from "./common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import {CrewDialog} from "./crew/CrewDialog";
import {HomeLocalStorageKeys} from "./HomeLocalStorageKeys";


interface Props {
    auth: Auth,
    tocAvailability: TocAvailability,
    backendServerAvailability: BackendServerAvailability,
    startUpdate: () => void,
    resetProgress: () => void,
    offlineUpdateSummary?: OfflineUpdateSummary,
    lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined,
    downloadOfFilesNecessary: boolean
}

export default function Home(props: Props) {

    const [isShortHelpDialogOpen, setIsShortHelpDialogOpen] = useStateWithLocalStoragePersistenceAndDefault<boolean>(HomeLocalStorageKeys.isShortHelpDialogOpenKey, true);

    const [isCrewDialogOpen, setIsCrewDialogOpen] = useStateWithLocalStoragePersistenceAndDefault<boolean>(HomeLocalStorageKeys.isCrewDialogOpenKey, true);

    //also read / written in Playlist component
    const [currentCrewIdentifier, setCurrentCrewIdentifier] = useStateWithLocalStoragePersistenceAndDefault<string | undefined>(HomeLocalStorageKeys.currentCrewIdentifierKey, undefined);

    return (
        <>
            <Header/>
            <MainMenu
                setIsShortHelpDialogOpen={setIsShortHelpDialogOpen}
                isShortHelpDialogOpen={isShortHelpDialogOpen}
                setIsCrewDialogOpen={setIsCrewDialogOpen}
                isCrewDialogOpen={isCrewDialogOpen}
            />

            <OfflineComponentsHolder auth={props.auth}
                                     currentBackendServerAvailability={props.backendServerAvailability}
                                     resetProgress={props.resetProgress}
                                     startUpdate={props.startUpdate}
                                     offlineUpdateSummary={props.offlineUpdateSummary}
                                     downloadOfFilesNecessary={props.downloadOfFilesNecessary}/>

            <NoCrewMembershipFoundAlert/>


            {isCrewDialogOpen && <CrewDialog
                closeDialog={() => setIsCrewDialogOpen(false)}
                currentCrewIdentifier={currentCrewIdentifier}
                setCurrentCrewIdentifier={setCurrentCrewIdentifier}
            />}

            {isShortHelpDialogOpen && <ShortHelp
                closeDialog={() => setIsShortHelpDialogOpen(false)}
            />}

            <PlaylistIndex lookupSong={props.lookupSong} currentCrewIdentifier={currentCrewIdentifier}
                           setCurrentCrewIdentifier={setCurrentCrewIdentifier}/>

        </>
    );
}