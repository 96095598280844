import * as React from 'react';
import {useEffect, useState} from 'react';
import {TocEntryDTO} from "../dto/v1/toc/TocEntryDTO";
import PlaylistData from "./data/PlaylistData";
import PlaylistMapper from "./mapper/PlaylistMapper";
import {TocIdDTO} from "../dto/v3/toc/TocIdDTO";
import ToCIndex from "./index/ToCIndex";
import {SearchEngine} from "../search/SearchEngine";
import PlaylistOverview from "./PlaylistOverview";
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {PlaylistRoutePaths} from "./PlaylistRoutePaths";
import useStateWithLocalStoragePersistenceAndMapping
    from "../common/hooks/useStateWithLocalStoragePersistenceAndMapping";
import {ItemId} from "./data/PlaylistTypes";
import ArrayUtils from "../common/ArrayUtils";
import PlaylistManipulationUtils from "./PlaylistManipulationUtils";
import PlaylistItemData from "./data/PlaylistItemData";
import {PlaylistLocalStoragePersistence} from "../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {PlaylistDTO} from "../dto/v3/playlist/PlaylistDTO";
import {SongsheetBeamerView} from "../beamer/SongsheetBeamerView";
import Auth from "../auth/Auth";
import {SongsheetCarousel} from "../carousel/SongsheetCarousel";
import {Client} from "urql";
import {DeviceInfoContext, useDeviceContextStateProvider} from "../device/DeviceInfoContext";
import {HomeLocalStorageKeys} from "../HomeLocalStorageKeys";
import {CrewLiveContext, useCrewLiveContextStateProvider} from "../live/CrewLiveContext";
import LocalStoragePersistence from "../localStoragePersistence/common/LocalStoragePersistence";


interface Props {
    playlistIdentifier: string
    lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined
    searchEngine: SearchEngine
    auth: Auth,
    graphQlClient: Client
}

const playlistMapper = new PlaylistMapper();

const playlistLocalStoragePersistence = new PlaylistLocalStoragePersistence();

function Playlist(props: Props) {

    let match = useRouteMatch();
    const [playlistData, setPlaylistData] = useStateWithLocalStoragePersistenceAndMapping<PlaylistData, PlaylistDTO>(
        props.playlistIdentifier,
        playlistMapper.dataToPersistedMapper(),
        playlistMapper.persistedToDataMapper(props.lookupSong),
        () => {
            throw new Error("We don't create playlists with this default mechanism")
        },
        playlistLocalStoragePersistence);

    const currentCrewIdentifier = playlistData.belongsToCrew

    //persist the crew identifier of this playlist as the current crew
    useEffect(() => {
        LocalStoragePersistence.persist(HomeLocalStorageKeys.currentCrewIdentifierKey, currentCrewIdentifier);
    }, [currentCrewIdentifier]);

    const deviceContextState = useDeviceContextStateProvider()

    const crewLiveContextState = useCrewLiveContextStateProvider(currentCrewIdentifier)

    const [selectedItem, setSelectedItem] = useState<PlaylistItemData | undefined>(undefined)

    return (
        <DeviceInfoContext.Provider value={deviceContextState}>
            <CrewLiveContext.Provider value={crewLiveContextState}>
                <Switch>
                    <Route path={match.path} exact>
                        <PlaylistOverview
                            {...props}
                            hasInvalidItems={PlaylistManipulationUtils.hasInvalidItemsWithNoCEntry(playlistData)}
                            removeInvalidItems={removeInvalidItems}
                            playlistData={playlistData}
                            setTitle={(title: string) => setPlaylistData(prev => {
                                return prev.copyWithTitle(title);
                            })}
                            moveUp={moveUp}
                            moveDown={moveDown}
                            remove={remove}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            graphQlClient={props.graphQlClient}
                        />
                    </Route>
                    <Route path={`${match.path}/${PlaylistRoutePaths.TOC_INDEX}`}>
                        <ToCIndex
                            playlistIdentifier={props.playlistIdentifier}
                            playlistData={playlistData}
                            insertAfter={insertAfter}
                            searchEngine={props.searchEngine}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                        />
                    </Route>
                    <Route path={`${match.path}/${PlaylistRoutePaths.CAROUSEL}`}>
                        <SongsheetCarousel
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            {...props}
                            playlistData={playlistData}
                            graphQlClient={props.graphQlClient}
                        />
                    </Route>
                    <Route path={`${match.path}/${PlaylistRoutePaths.BEAMER_VIEW}`}>
                        <SongsheetBeamerView/>
                    </Route>
                </Switch>
            </CrewLiveContext.Provider>
        </DeviceInfoContext.Provider>
    );

    function moveUp(itemId: ItemId) {
        setPlaylistData(PlaylistManipulationUtils.up(playlistData, itemId));
    }

    function moveDown(itemId: ItemId) {
        setPlaylistData(PlaylistManipulationUtils.down(playlistData, itemId));
    }

    function remove(itemId: ItemId) {
        const nextItem = ArrayUtils.followingOf(playlistData.getItems(), i => i.itemInMemoryId === itemId);
        const prevItem = ArrayUtils.previousOf(playlistData.getItems(), i => i.itemInMemoryId === itemId);
        const activeItem = nextItem || prevItem;
        setPlaylistData(PlaylistManipulationUtils.remove(playlistData, itemId));
        setSelectedItem(activeItem)
    }

    function insertAfter(tocId: TocIdDTO, itemId?: ItemId): PlaylistItemData {
        const itemToBeInserted = PlaylistManipulationUtils.createItemToInsert(playlistData.getItems(), props.lookupSong, tocId);
        setPlaylistData(PlaylistManipulationUtils.insertAfter(playlistData, itemToBeInserted, itemId));
        return itemToBeInserted;
    }

    function removeInvalidItems() {
        const cleanedData = PlaylistManipulationUtils.removeInvalidItemsWithNoCEntry(playlistData);
        setPlaylistData(cleanedData);
    }
}

export default Playlist;
