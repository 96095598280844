export const FAKE_TOKEN_PREFIX = "devTestToken"
export const ACCESS_TOKEN_TO_USE_STORAGE_KEY = "AuthAlwaysAuthorizedStub-accessTokenToUse";


export type TokenContent = { permissions: string[] }

export class StubTokenDefinition {
    private readonly _tokenWithoutPrefix: string
    private readonly _content: TokenContent

    constructor(tokenWithoutPrefix: string, permissions: string[] = []) {
        this._tokenWithoutPrefix = tokenWithoutPrefix;
        this._content = {permissions: permissions}
    }

    get tokenWithoutPrefix(): string {
        return this._tokenWithoutPrefix;
    }

    get content(): TokenContent {
        return this._content;
    }

    token() {
        return `${FAKE_TOKEN_PREFIX}${this._tokenWithoutPrefix}`
    }

}

