import * as React from 'react';
import {DeviceInfo} from "../../../device/DeviceInfoContext";
import {OtherActiveDevices} from "./OtherActiveDevices";
import {OwnDevice} from "./OwnDevice";
import {NoCrewInfo} from "./NoCrewInfo";
import {CyLivePosition} from "./CyLivePosition";

interface Props {
    deviceInfo: DeviceInfo
    setDeviceReadableName: (name: string) => void
    setDeviceColor: (color: string) => void
    devicesInPlaylist: readonly DeviceInfo[]
    listOfFollowedDevices: string[]
    setListOfFollowedDevices: (value: (prevState: string[]) => string[]) => void
    currentCrewIdentifier: string | undefined
}

export function PlaylistLivePositionSidebarBody(props: Props) {

    return (
        <div data-cy={CyLivePosition.LivePositionSidebarBody}>
            {props.currentCrewIdentifier !== undefined ?
                <OtherActiveDevices {...props} currentCrewIdentifier={props.currentCrewIdentifier}/>
                : <NoCrewInfo/>}
            <OwnDevice {...props}/>
        </div>
    );

}

