import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Input, InputGroup, InputProps} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencilAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useHotkeys} from "react-hotkeys-hook";
import {CyTextEditable} from "./CyTextEditable";
import _ from "lodash";
import {HotkeysEvent} from "react-hotkeys-hook/dist/types";

interface Props extends InputProps {
    children?: React.ReactNode
    content: string
    updateContent: (content: string) => void;
    editAllowed: boolean
    cyData?: string
}

export function InputEditable(props: Props) {

    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>("")

    function handleHotKey(browserEvent: KeyboardEvent, hotKeyEvent: HotkeysEvent) {
        if (hotKeyEvent.keys?.includes('enter')) {
            saveAndCloseEditMode()
        } else if (hotKeyEvent.keys?.includes('escape')) {
            cancelEditMode()
        } else {
            console.warn("Unknown hotkey event")
        }
    }

    const inputfield = useHotkeys<HTMLInputElement>(
        ['enter', 'escape'],
        handleHotKey,
        {
            enableOnFormTags: ['input'],
            //if we would be in a form-tag, pressing enter would send the form
            preventDefault: true
        }
    )

    function enterEditMode() {
        setIsEditing(true)
        setInputValue(props.content)
    }

    useEffect(() => {
        const element = inputfield.current;
        if (element) {
            element.focus();
            element.select();
        }
    }, [inputfield, isEditing]);

    function cancelEditMode() {
        setIsEditing(false)
    }

    function saveAndCloseEditMode() {
        props.updateContent(inputValue);
        setIsEditing(false)
    }

    function updateInputValue(evt: React.FormEvent<HTMLInputElement>) {
        setInputValue(evt.currentTarget.value)
    }

    //This is a workaround to prevent the additional attributes from being passed to the input field
    const inputPropsOnly = _.omit(props, ['content', 'updateContent', 'editAllowed', 'children', 'cyData']);

    return (
        <span data-cy={props.cyData}>
            <InputGroup style={{display: (isEditing ? "flex" : "none")}}>
                <Input
                    {...inputPropsOnly}
                    value={inputValue}
                    onChange={evt => updateInputValue(evt)}
                    innerRef={inputfield}
                    data-cy={CyTextEditable.InputField}
                />
                <Button color="primary" onClick={() => saveAndCloseEditMode()}
                        data-cy={CyTextEditable.SaveButton}><FontAwesomeIcon icon={faCheck}/></Button>
                <Button color="primary" onClick={() => cancelEditMode()}><FontAwesomeIcon icon={faTimes}/></Button>
            </InputGroup>
            <span style={{display: (isEditing ? "none" : "inline")}}>
                <span data-cy={CyTextEditable.TextEditableValue}>
                    {props.children}
                </span>
                {props.editAllowed &&
                    <Button
                        color="link"
                        onClick={() => enterEditMode()}
                        data-cy={CyTextEditable.EditButton}
                    >
                        <FontAwesomeIcon icon={faPencilAlt}/>
                    </Button>}
            </span>
        </span>
    );
}