import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Card, CardBody, CardHeader, ListGroup, ListGroupItem} from 'reactstrap';
import {TocEntryDTO} from "../../dto/v1/toc/TocEntryDTO";
import PlaylistData from "../data/PlaylistData";
import PlaylistMapper from "../mapper/PlaylistMapper";
import SharePlaylistMenu from "./SharePlaylistMenu";
import {SongbookMapExtractor} from "./SongbookMapExtractor";
import {PreferredSongnumberFinder} from "./PreferredSongnumberFinder";
import QRCode from 'qrcode.react';
import {PlaylistFromUrlImportParser} from "../import/url/PlaylistFromUrlImportParser";
import {RoutePaths} from "../../RoutePaths";
import {TocIdDTO, TocIdDTOCompanion} from "../../dto/v3/toc/TocIdDTO";
import useToggleValueWithLocalStoragePersistence from "../../common/hooks/useToggleValueWithLocalStoragePersistence";
import useStateWithLocalStoragePersistenceAndMapping
    from "../../common/hooks/useStateWithLocalStoragePersistenceAndMapping";
import {useStateWithLocalStoragePersistence} from "../../common/hooks/useStateWithLocalStoragePersistenceAndDefault";
import Header from "../../Header";
import {
    PlaylistLocalStoragePersistence
} from "../../localStoragePersistence/v3/playlist/PlaylistLocalStoragePersistence";
import {PlaylistDTO} from "../../dto/v3/playlist/PlaylistDTO";
import {InvalidItemMessage, InvalidItemMessageTitlePrefix} from "../item/PlaylistItemTexts";
import {LocalStorageKey} from "../../localStoragePersistence/common/LocalStorageKey";
import {UiConfig} from "../../Config";
import {CopyToClipboardButton} from "../../common/CopyToClipboardButton";
import {SharePlaylistTexts} from "./SharePlaylistTexts";


interface Props {
    identifier: string
    lookupSong: (tocId: TocIdDTO) => TocEntryDTO | undefined
}

const PERSISTENCE_GROUP = "SharePlaylist";

const playlistMapper = new PlaylistMapper();

function SharePlaylist(props: Props) {

    const {identifier} = props;

    const textarea = useRef<HTMLTextAreaElement>(null);

    const [showTonality, setShowTonality] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey(PERSISTENCE_GROUP, "showTonality"), false);
    const playlistData = useStateWithLocalStoragePersistenceAndMapping<PlaylistData, PlaylistDTO>(
        props.identifier,
        playlistMapper.dataToPersistedMapper(),
        playlistMapper.persistedToDataMapper(props.lookupSong),
        () => {
            throw new Error("We don't create playlists with this default mechanism")
        },
        new PlaylistLocalStoragePersistence())[0];

    const [currentSongbookId, setCurrentSongbookId] = useStateWithLocalStoragePersistence<number>(new LocalStorageKey(PERSISTENCE_GROUP, "currentSongbookId"));

    const [textareaContent, setTextareaContent] = useState<string>("");

    useEffect(() => {
        const element = textarea.current;
        if (element) {
            const lines: string[] = [];
            playlistData.getItems().forEach((entry) => {
                if (entry.toCEntry) {
                    lines.push(generatePlaylistItem(entry.toCEntry, showTonality, currentSongbookId));
                } else {
                    lines.push("---");
                    lines.push(InvalidItemMessageTitlePrefix + " " + entry.tocId?.sourceSystem + "_" + entry.tocId?.songId);
                    lines.push(InvalidItemMessage);
                    lines.push("---");
                }
            });

            const content = lines.join("\n") + "\n\nPlaylist in Songship importieren: " + generateImportLink(playlistData, identifier);
            setTextareaContent(content);
            element.value = content;
        }
    }, [identifier, playlistData, showTonality, currentSongbookId])

    return (
        <>
            <Header/>
            <SharePlaylistMenu
                playlistIdentifier={props.identifier}
                showTonality={showTonality}
                setShowTonality={setShowTonality}
                currentSongbookId={currentSongbookId}
                setCurrentSongbookId={setCurrentSongbookId}
                songbookMap={new SongbookMapExtractor().extractSongbooks(playlistData.getAllValidTocEntries())}
            />

            <br/>

            <Card>
                <CardHeader>
                    <h2 className={"light-header"}>
                        {playlistData.title}
                    </h2>
                </CardHeader>
                <CardBody>

                    <ListGroup flush>
                        <ListGroupItem>
                            <h2 style={{fontWeight: 300}}>Als Text teilen</h2>
                            <p className="mb-4">
                                Kopiere den Inhalt in die Zwischenablage und teile ihn. Wenn du mit einem bestimmten
                                Liederbuch arbeitest, kannst du es oben auswählen. Wenn möglich werden
                                dann die Liedernummern aus diesem Buch aufgeführt.
                            </p>
                            <p className="mb-2">
                                <CopyToClipboardButton
                                    buttonValue={SharePlaylistTexts.CopyTextareaContent}
                                    content={textareaContent}
                                />
                            </p>
                            <textarea rows={15} style={{tabSize: 16, width: "100%"}} ref={textarea}/>
                        </ListGroupItem>
                        <ListGroupItem>
                            <h2 style={{fontWeight: 300}}>QR Code teilen</h2>
                            <p className="mb-4">
                                Zeige diesen QR Code einer anderen Person, die Songship hat. Sie kann den Code scannen
                                und
                                so automatisch alle Lieder dieser Playlist in ihre einfügen - ohne
                                Internet.
                            </p>
                            <QRCode value={generateImportLink(playlistData, props.identifier)} size={250}
                                    renderAs="svg"/>
                        </ListGroupItem>
                    </ListGroup>
                </CardBody>
            </Card>
        </>
    );

}

function generateImportLink(playlistData: PlaylistData, identifier: string): string {
    const songIds: string[] = [];
    playlistData.getItems().forEach((entry) => {
        if (entry.tocId) {
            //Valid and invalid entries
            songIds.push(TocIdDTOCompanion.stringify(entry.tocId));
        }
    });
    return `${UiConfig.baseUrl}/${RoutePaths.IMPORT_PLAYLIST}/${identifier}?${PlaylistFromUrlImportParser.PARAM_KEY_NAME}=${songIds.join(",")}`;
}

function generatePlaylistItem(toCEntry: TocEntryDTO, showTonality: boolean, currentSongbookId: number | undefined): string {
    const tonality = showTonality ? " (" + toCEntry.tonality + ")" : "";
    return new PreferredSongnumberFinder().findPreferredSongnumber(toCEntry, currentSongbookId) + "\t" + toCEntry.title + tonality;
}

export default SharePlaylist;
