import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import {CyLivePositionMarker} from "./CyLivePositionMarker";

interface Props {
    deviceIdentifier: string,
    color: string,
    className?: string | undefined;
}

export function LivePositionMarker(props: Props) {
    return (
        <span style={{color: props.color}} className={props.className} data-cy={CyLivePositionMarker.Marker} data-deviceidentifier={props.deviceIdentifier}><FontAwesomeIcon icon={faCircleUser} /></span>
    );
}