import {LiveEventUbInputType} from "../gql/graphql";
import {useEffect, useState} from "react";
import {PlaylistLivePosition, PlaylistLivePositionEventName} from "../live/playlist/PlaylistLivePosition";

export function usePlaylistLivePositionFromEventSource(eventSource: EventSource | undefined): {
    playlistLivePosition: ReadonlyMap<string, PlaylistLivePosition>,
    playlistLivePositionEventSourceHandlerIsReady: boolean,
    devicesOnline: readonly string[]
} {
    const [playlistLivePosition, setPlaylistLivePosition] = useState<Map<string, PlaylistLivePosition>>(new Map());
    const [devicesOnline, setDevicesOnline] = useState<string[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        if (!eventSource) return;

        // attaching a handler to receive message events
        const handler = (event: MessageEvent) => {
            if (event.data === "beat") return;

            const data = JSON.parse(event.data);
            let v: LiveEventUbInputType = {...data};

            if (v.eventName === PlaylistLivePositionEventName) {
                const payload: PlaylistLivePosition = JSON.parse(v.payload);
                console.debug(`${v.eventName} from ${v.deviceReadableName}: slide=${payload.activePageInCarousel}, lyricsPosition=${payload.currentLyricsPosition}`, {
                    event: data,
                    payload: payload
                });

                setPlaylistLivePosition(prev => new Map(prev.set(v.deviceIdentifier, payload)));
                setDevicesOnline(prev => {
                    if (prev.includes(v.deviceIdentifier)) return prev;
                    else return [...prev, v.deviceIdentifier];
                });
            }
        };

        // eventSource.onmessage = handler;
        eventSource.addEventListener("message", handler);

        setIsReady(true)

        return () => {
            console.debug(`Removing event source listener for ${PlaylistLivePositionEventName}`);
            eventSource.removeEventListener("message", handler);
        };
    }, [eventSource]);

    return {playlistLivePosition, playlistLivePositionEventSourceHandlerIsReady: isReady, devicesOnline};
}