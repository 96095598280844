import React, {useEffect} from 'react';
import {Alert, Button, Card, CardBody, CardHeader, ListGroup, ListGroupItem, Nav, Navbar, NavItem} from "reactstrap";
import NavItemTogglePersistent from "../common/NavItemTogglePersistent";
import {Link, useHistory} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faShareAlt} from "@fortawesome/free-solid-svg-icons";
import PlaylistFooterMenu from "./PlaylistFooterMenu";
import PlaylistData from "./data/PlaylistData";
import PlaylistMenu from "./PlaylistMenu";
import useToggleValueWithLocalStoragePersistence from "../common/hooks/useToggleValueWithLocalStoragePersistence";
import {PlaylistRoutePaths} from "./PlaylistRoutePaths";
import PlaylistItemData from "./data/PlaylistItemData";
import Header from "../Header";
import {ImportedPlaylistInfo} from "./ImportedPlaylistInfo";
import {PushNeededInfo} from "./PushNeededInfo";
import {CyPlaylist} from "./CyPlaylist";
import {RenderPermissionBased} from "../auth/RenderPermissionBased";
import Auth from "../auth/Auth";
import {Permission} from "../auth/Permission";
import Entries from "./overview/Entries";
import {TitleEditable} from "./TitleEditable";
import {RoutePaths} from "../RoutePaths";
import {useDeviceContext} from "../device/DeviceInfoContext";
import {pushPlaylistLivePosition} from "../live/playlist/PlaylistLivePositionPusher";
import {Client} from "urql";
import {useCrewLiveContext} from "../live/CrewLiveContext";
import {LocalStorageKey} from "../localStoragePersistence/common/LocalStorageKey";

interface Props {
    playlistData: PlaylistData
    setTitle: (title: string) => void
    moveUp: (itemId: number) => void;
    moveDown: (itemId: number) => void;
    remove: (itemId: number) => void;
    hasInvalidItems: boolean;
    removeInvalidItems: () => void;
    selectedItem: PlaylistItemData | undefined;
    setSelectedItem: (item: PlaylistItemData | undefined) => void;
    auth: Auth;
    graphQlClient: Client;
}

export default function PlaylistOverview(props: Props) {
    // props which are used inside an effect
    // https://github.com/facebook/react/issues/16265#issuecomment-517518539
    const {graphQlClient, playlistData} = props;

    const [showImageThumbnails, setShowImageThumbnails] = useToggleValueWithLocalStoragePersistence(new LocalStorageKey("Playlist", "showImageThumbnails"), false);

    const history = useHistory();

    const {
        deviceIdentifier,
        deviceReadableName,
        deviceColor,
    } = useDeviceContext()

    const {
        currentCrewIdentifier,
        playlistLivePositionEventSourceHandlerIsReady,
        devicesOnline
    } = useCrewLiveContext();

    function itemClickedHandler(item: PlaylistItemData) {
        if (item.itemInMemoryId === props.selectedItem?.itemInMemoryId)
            history.push(PlaylistRoutePaths.toCarousel(props.playlistData.getIdentifier()));
        else
            props.setSelectedItem(item);
    }

    useEffect(() => {
        if (currentCrewIdentifier === undefined || !playlistLivePositionEventSourceHandlerIsReady) return;

        pushPlaylistLivePosition(
            graphQlClient,
            currentCrewIdentifier,
            {
                deviceIdentifier,
                deviceReadableName,
                deviceColor,
                playlistIdentifier: playlistData.getIdentifier(),
                timestamp: Date.now(),
                selectedPlaylistItemInMemoryId: undefined,
                viewMode: "lyrics", //doesn't matter
                currentLyricsPosition: undefined,
                activePageInCarousel: undefined,
            }
        )
    }, [playlistLivePositionEventSourceHandlerIsReady, devicesOnline, currentCrewIdentifier, graphQlClient, deviceIdentifier, deviceReadableName, deviceColor, playlistData]);

    return (
        <>
            <Header/>
            <Navbar color="light" className="navbar-expand" light>
                <Nav navbar>
                    <NavItem>
                        <Button tag={Link} className="nav-link" to="/" color="link"><FontAwesomeIcon
                            icon={faHome}/> Home</Button>
                    </NavItem>
                </Nav>
            </Navbar>
            <br/>
            <Card>
                <CardHeader className="pb-0" style={{backgroundColor: "#f8f9fa", borderBottom: "none"}}>
                    <h2 className={"light-header"}>
                        <TitleEditable content={props.playlistData.title} updateContent={props.setTitle}
                                       editAllowed={!props.playlistData.isQrCodeScannedPlaylist}/>
                    </h2>
                    <Navbar light expand="lg">
                        <Nav className="me-auto" navbar>
                            <RenderPermissionBased auth={props.auth} requiredPermissions={[Permission.songsheetsRead]}>
                                <NavItemTogglePersistent value="Vorschaubilder anzeigen"
                                                         setIsActive={setShowImageThumbnails}
                                                         isActive={showImageThumbnails}/>
                            </RenderPermissionBased>
                            <NavItem>
                                <Link className="nav-link"
                                      to={`/${RoutePaths.SHARE_PLAYLIST}/` + props.playlistData.getIdentifier()}><FontAwesomeIcon
                                    icon={faShareAlt}/> Teilen</Link>
                            </NavItem>
                        </Nav>
                    </Navbar>
                </CardHeader>
                <CardHeader className="sticky-top" style={{backgroundColor: "#f8f9fa"}}>
                    {!props.playlistData.isQrCodeScannedPlaylist &&
                        <PlaylistMenu
                            {...props}
                            playlistIdentifier={props.playlistData.getIdentifier()}
                            selectedItemId={props.selectedItem?.itemInMemoryId}
                        />}
                </CardHeader>
                <CardBody>
                    <ListGroup flush>
                        {!props.playlistData.isQrCodeScannedPlaylist && props.hasInvalidItems && <ListGroupItem>
                            <Alert color="warning" data-cy={CyPlaylist.AlertUnknownSongs}>
                                <p>Unbekannte Lieder</p>
                                <p>Diese Playlist enthält unbekannte Lieder. Willst du sie bereinigen und alle
                                    unbekannten Lieder entfernen?</p>
                                <p>
                                    <Button onClick={() => props.removeInvalidItems()}
                                            color="warning">Bereinigen</Button>
                                </p>
                            </Alert>
                        </ListGroupItem>
                        }
                        {props.playlistData.isQrCodeScannedPlaylist &&
                            <ImportedPlaylistInfo playlistData={props.playlistData}/>}
                        {props.playlistData.needsPush && <PushNeededInfo/>}
                        <Entries
                            playlistData={props.playlistData}
                            selectedItem={props.selectedItem}
                            itemClickedHandler={itemClickedHandler}
                            showImageThumbnails={showImageThumbnails}
                        />

                        {!props.playlistData.isQrCodeScannedPlaylist &&
                            <ListGroupItem className="footer">
                                <PlaylistFooterMenu
                                    {...props}
                                    playlistIdentifier={props.playlistData.getIdentifier()}
                                />
                            </ListGroupItem>
                        }
                    </ListGroup>
                </CardBody>
            </Card>
        </>
    );
}
