import {Dispatch, SetStateAction, useEffect, useState} from "react";
import LocalStoragePersistence from "../../localStoragePersistence/common/LocalStoragePersistence";
import {LocalStorageKey} from "../../localStoragePersistence/common/LocalStorageKey";

export function useStateWithLocalStoragePersistenceAndDefault<S>(localStorageKey: LocalStorageKey, defaultValue: S): [S, Dispatch<SetStateAction<S>>] {
    const [value, setValue]: [(S), React.Dispatch<React.SetStateAction<S>>] = useState(() => LocalStoragePersistence.read(localStorageKey, defaultValue));

    useEffect(() => {
        LocalStoragePersistence.persist(localStorageKey, value);
    }, [localStorageKey, value]);

    return [value, setValue];
}

export function useStateWithLocalStoragePersistence<S>(localStorageKey: LocalStorageKey): [S | undefined, Dispatch<SetStateAction<S | undefined>>] {
    const [value, setValue]: [(S | undefined), React.Dispatch<React.SetStateAction<S | undefined>>] = useState(() => LocalStoragePersistence.read(localStorageKey, undefined));

    useEffect(() => {
        LocalStoragePersistence.persist(localStorageKey, value);
    }, [value, localStorageKey]);

    return [value, setValue];
}