import {StubTokenDefinition} from "./stub/StubTokenDefinition";

const permissionListWithSongsheetsRead = ["songsheets:read"]

export const noCrewPayingUser = new StubTokenDefinition("NoCrewPayingUser", permissionListWithSongsheetsRead)
export const adorayOnlyUserTokenDefinition = new StubTokenDefinition("AdorayOnlyUser")
export const adorayLuzernOnlyUserTokenDefinition = new StubTokenDefinition("AdorayLuzernOnlyUser")
export const payingAdorayLuzernOnlyUserTokenDefinition = new StubTokenDefinition("PayingAdorayLuzernOnlyUser", permissionListWithSongsheetsRead)
export const adorayBernOnlyUserTokenDefinition = new StubTokenDefinition("AdorayBernOnlyUser")

export const payingAdorayNomintUserTokenDefinition = new StubTokenDefinition("PayingAdorayNomintUser", permissionListWithSongsheetsRead)

export function permissionsFor(stubToken: StubTokenDefinition): readonly string[] {
    let fakeTokenEntry = auth0UserStub.find(f => f.tokenWithoutPrefix === stubToken.tokenWithoutPrefix);
    return fakeTokenEntry ? fakeTokenEntry.content.permissions : []
}

//keep in sync with SecurityTokenUtils on server side
export const auth0UserStub: StubTokenDefinition[] = [
    noCrewPayingUser,
    new StubTokenDefinition("NomintOnlyUser"),
    adorayOnlyUserTokenDefinition,
    adorayLuzernOnlyUserTokenDefinition,
    payingAdorayLuzernOnlyUserTokenDefinition,
    adorayBernOnlyUserTokenDefinition,
    new StubTokenDefinition("WallisOnlyUser"),
    new StubTokenDefinition("AdorayNomintUser"),
    payingAdorayNomintUserTokenDefinition,
    new StubTokenDefinition("PayingAdorayNomintSecondUser", permissionListWithSongsheetsRead),
    new StubTokenDefinition("AdorayWallisUser"),
    new StubTokenDefinition("WallisNomintUser"),
    new StubTokenDefinition("NomintAdorayWallisUser"),
]
